import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Container } from "@mui/material";

// import classes from "./App.module.css";
import NavBar from "./shared/components/Navigation/NavBar";
import useAuth from "./shared/hooks/auth-hook";

const Home = React.lazy(() => import("./shared/pages/Home"));
const Collection = React.lazy(() => import("./collections/pages/Collection"));
const Item = React.lazy(() => import("./items/pages/Item"));
const Media = React.lazy(() => import("./items/pages/Media"));
const Profile = React.lazy(() => import("./users/pages/Profile"));
const Authenticate = React.lazy(() => import("./users/pages/Authenticate"));
const Favorites = React.lazy(() => import("./shared/pages/Favorites"));

function App() {
  const { token } = useAuth();

  const routes = (
    <Routes>
      <Route path="/" element={<Navigate replace to="/home" />} />
      <Route path="/home" element={<Home />} />
      {!token && <Route path="/authenticate" element={<Authenticate />} />}
      <Route path="/favorites" element={<Favorites />} />
      <Route path="/profile/:userId" element={<Profile />} />
      <Route path="/collection/:collectionId" element={<Collection />} />
      <Route path="/item/:itemId/media/:mediaName" element={<Media />} />
      <Route path="/item/:itemId" element={<Item />} />
      <Route path="*" element={<Navigate replace to="/home" />} />
    </Routes>
  );

  /* if (token) {
    routes = (
      <Routes>
        <Route path="/" element={<Navigate replace to="/home" />} />
        <Route path="/home" element={<Home />} />
        <Route path="/profile/:userId" element={<Profile />} />
        <Route path="/collection/:collectionId" element={<Collection />} />
        <Route path="/item/:itemId/media/:mediaName" element={<Media />} />
        <Route path="/item/:itemId" element={<Item />} />
        <Route path="*" element={<Navigate replace to="/home" />} />
      </Routes>
    );
  } else {
    routes = (
      <Routes>
        <Route path="/" element={<Navigate replace to="/home" />} />
        <Route path="/home" element={<Home />} />
        <Route path="/authenticate" element={<Authenticate />} />
        <Route path="/profile/:userId" element={<Profile />} />
        <Route path="/collection/:collectionId" element={<Collection />} />
        <Route path="/item/:itemId/media/:mediaName" element={<Media />} />
        <Route path="/item/:itemId" element={<Item />} />
      </Routes>
    );
  } */

  const theme = createTheme({
    palette: {
      mode: "dark",
      primary: { main: "#fd9801" },
      secondary: { main: "#198391" },
      success: { main: "#388e3c" },
      error: { main: "#fc3628" },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Container
        sx={{
          padding: "0 !important",
          minHeight: "100vh",
          backgroundColor: theme.palette.background.default,
        }}
      >
        <NavBar />
        <Suspense fallback={<div>Loading</div>}>{routes}</Suspense>
      </Container>
      {/* <div
        className={classes.appMain}
        style={{ backgroundColor: theme.palette.background.default }}
      >
        <NavBar />
        {routes}
      </div> */}
    </ThemeProvider>
  );
}

export default App;
